#info-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(127,230,239, 1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    font-size: 16px;
}

#info-overlay-first {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    font-size: 16px;
}

#info-div {
    background-color: rgb(127,230,239);
    color: white;
    border-radius: 10px;
    height: auto;
    width: 80vw;
    max-height: 80vh;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    font-family: 'Quicksand', sans-serif;
    color: black;
    min-width: 400px;
    margin-top: 8vh;
}

#info-div-first {
    background-color: rgb(127,230,239);
    color: white;
    border-radius: 10px;
    height: auto;
    width: 80vw;
    max-height: 80vh;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
    font-family: 'Quicksand', sans-serif;
    color: black;
    min-width: 320px;
    box-shadow: 0px 0px 50px 0 rgb(0, 0, 0);
}

.info-item {
    padding: 1rem;
    text-align: center;
    width: 100%;
}

#info-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
}

.info-top-div {
    width: 33%;
}

#info-top-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 1rem;
}

#info-close{
    font-size: 22px;
}

#info-close:hover {
    cursor: pointer;
}

#info-game-info {
    border-bottom: solid;
    border-width: 1px;
    width: 100%;
}

#info-char-text {
    margin-bottom: 1rem;
}

#info-char-details {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#char-img {
    height: 80%;
    max-height: 300px;
    background-color: rgba(0,0,0,0);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

#info-go {
    border: none;
    margin-bottom: 1rem;
    width: 8rem;
    background-color: rgb(255,229,44);
    transition: 1s;
    font-size: 18px;
    /* border-radius: 10px; */
}

#info-go:hover {
    background-color: rgb(250,166,34);
}

#info-artist {
    border-top: solid;
    border-width: 1px;
    color: black;
    border-color: black;
}

@media screen and (max-width: 450px){
    #info-top {
        width: 80%;
    }
    .info-item {
        width: 80%;
    }
    #info-div-first {
        font-size: 80%;
    }
    #info-top-middle {
        font-size: 80%;
    }
    #char-img {
        max-height: 220px;
    }
    #info-go {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media screen and (max-height: 650px){
    #info-div-first {
        font-size: 80%;
    }
    #info-top-middle {
        font-size: 80%;
    }
    #char-img {
        max-height: 200px;
    }
    #info-go {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .info-item {
        padding: .7rem;
    }
}