.circle-div {
    font-size: 50%;
    font-weight: bolder;
    background-color: rgba(37, 45, 53, 0.75);
    border-radius: 50%;
    width: 5em;
    height: 5em;
    margin: 0;
  /* use absolute positioning  */
    position: absolute;
    list-style: none;
    box-shadow: 0 0 20px 0 #ccc;
    opacity: 1;
    transition: opacity 0.5s linear;
    color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    
    border: solid;
    border-color: white;
    border-width: 2px;
}

.circle-div:hover {
  cursor: pointer;
}

#checkmark {
  font-size: 3em;
  color: rgb(75, 211, 75);
}

#miss-icon {
  font-size: 3em;
  color: rgb(202, 17, 17);
}

#aiming {
  font-size: 3em;
  color: rgb(127,230,239);
}

/* .circle-div p:hover {
  cursor: pointer;
} */