#not-found-div {
    width: 100%;
    height: 100vh;
    background-color: rgb(127,230,239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#not-found-button {
    background-color: rgb(255,229,44);
}