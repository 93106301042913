.App {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}



#game-img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}