.stats-overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    font-size: 18px;
}

#copy-alert {
    top: 7rem;
    background-color: rgb(196,215,12);
    border: none;
    position: fixed;
}

.stats-div {
    background-color: rgb(127,230,239);
    color: black;
    border-radius: 10px;
    box-shadow: 0px 0px 50px 0 rgb(0, 0, 0);
    /* height: 50vh; */
    width: 80vw;
    max-height: 600px;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    font-family: 'Quicksand', sans-serif;
}

#stats-close-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    padding-bottom: 0;
}

#stats-close{
    font-size: 22px;
}

#stats-close:hover {
    cursor: pointer;
}

.stats-page-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
}

#stats-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 1rem;
}

.stats-header {
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 20%;
    height: 100%;
}

.stat {
    font-size: 28px;
    padding: 0;
    margin: 0;
    text-align: center;
}

.stat-descriptor {
    font-size: 12px;
    padding: 0;
    margin: 0;
    text-align: center;
}

#distribution-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: left;
}

.distribution-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

#stats-bottom-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
}

#stats-next-game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

#stats-share-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}

#next-game-text {
    font-size: 16px;
    width: 80%;
    padding: 0;
    margin: 0;
    text-align: center;
}

#next-game-timer {
    font-size: 30px;
    width: 80%;
    padding: 0;
    margin: 0;
    text-align: center;
}

#stats-share-button {
    border: none;
    margin-bottom: 1rem;
    height: 3rem;
    width: 80%;
    background-color: rgb(255,229,44);
    transition: 1s;
    font-size: 18px;
    border-radius: 5px;
}

#stats-share-button:hover {
    background-color: rgb(250,166,34);
}

@media screen and (max-width: 480px){
    #next-game-text {
        font-size: 12px;
        width: 80%;
        padding: 0;
        margin: 0;
        text-align: center;
    }
    #next-game-timer {
        font-size: 26px;
    }
    #stats-share-button {
        height: 2.8rem;
        width: 80%;
        font-size: 16px;
    }
}

@media screen and (max-width: 420px){
    #next-game-timer {
        font-size: 24px;
    }
}
@media screen and (max-width: 380px){
    #next-game-timer {
        font-size: 20px;
    }
}