#navbar {
    height: 100%;
}

.nav-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 2.3vh;
    height: 100%;
}

@media screen and (max-width: 560px){
    .nav-div {
        font-size: 5vw;
    }
}

@media screen and (max-width: 360px){
    .nav-div {
        font-size: 4.5vw;
    }
}

.nav-content {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
}

#nav-brand {
    font-size: 150%;
}

#nav-brand:hover {
    cursor: default;
}

#nav-div-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

#nav-div-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.nav-link-div {
    color: black;
    text-decoration: none;
    transition: .25s;
    padding: .8rem;
}

.nav-link-div:hover {
    color: rgb(194,35,3);
    cursor: pointer;
}

#nav-artist {
    color: black;
    text-decoration: none;
    padding: 0;
}

#nav-artist:hover {
    color: rgb(194,35,3);
    cursor: pointer;
}

#nav-github {
    color: black;
    text-decoration: none;
    padding: 0;
}

#nav-github:hover {
    color: rgb(194,35,3);
    cursor: pointer;
}