.bar-chart-bar-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 20%;
    width: 100%;
}

.bar-graph-bar-div {
    width: 92%;
}

@media screen and (max-width: 500px){
    .bar-graph-bar-div {
        width: 90%;
    }
}

@media screen and (max-width: 380px){
    .bar-graph-bar-div {
        width: 88%;
    }
}

.bar-graph-bar {
    min-width: 3%;
    margin: 3px;
    height: 20px;
}

.bar-graph-label {
    font-size: 13px;
    width: 5%;
}

.value-label {
    text-align: right;
    color: white;
    padding-right: 3px;
    font-size: 12px;
    font-weight: bold;
}