.Layout-header {
    background-color: rgb(127,230,239);
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: black;
    min-height: 100vh;
}

.navbar {
    min-height: 6vh;
    max-height: 6vh;
    min-width: 100%;
    background-color: rgb(255,229,44);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 85vh;
}

.loading-div {
    background-color: rgb(127,230,239);
    display: flex;
    flex-direction: column;
    font-size: 40px;
    color: black;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}